import { RoodyEmotion } from '~/types/roody-emotion'

export default function ErrorStateWithRoody(props: {
  title: string
  body: string
  action?: React.ReactNode
}) {
  return (
    <div className="grid place-items-center">
      <div className="bg-white border border-red-500 rounded-3xl shadow-[0px_8px_24px_rgba(0,_0,_0,_0.08)] p-12 w-[360px]">
        <div className="-mt-32 flex justify-center mb-4">
          <div className="h-40 w-40">
            <img alt="oh no roody" src={RoodyEmotion.WARNING} />
          </div>
        </div>
        <h1 className="text-2xl text-app-gray-dark text-center mb-6">{props.title}</h1>
        <p className="text-app-gray-dark text-center">{props.body}</p>
        {props.action && props.action}
      </div>
    </div>
  )
}
